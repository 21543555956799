<template>
  <div class="anser-bottom-handle">
    <div class="left-list">
      <div class="left-list-title">
        <img class="question" src="../../../../assets/images/ai/question.png" alt="">
        <span>系统检测到多个匹配的功能名称，请从以下列表中选择您想查询的具体功能名称:</span>
      </div>
      <div class="inner-item mb-8" :class="index === tableData.length-1?'no-margin':''" v-for="(msg,index) in tableData"
           :key="msg">
        <div class="cursor exceed-hidden" @click="test(msg)">
          <span class="dot"></span>
          {{ msg }}
        </div>
      </div>
      <div class="left-list-title" style="padding-top: 8px">
        <span>请注意，如果您不选择具体功能名称，系统将无法继续进行查询。在您选择具体功能名称之前，系统不会
输出任何与”功能名称”相关的待办事项。</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    form_value: {
      type: String,
      default: () => {
        return '[]';
      }
    }
  },
  data() {
    return {
      tableData: []
    };
  },
  created() {
    // 由于 form_value 是字符串，我们需要解析它以获取数组
    this.tableData = JSON.parse(this.form_value);
  },
  methods: {
    test(msg) {
      let str = '查看' + msg + '项目的待办情况';
      // 调用父组件的方法
      this.$emit('call-parent-method', str);
    }
  }
};
</script>

<style lang="less" scoped>
.anser-bottom-handle {
  display: flex;
  flex-direction: row;
  // margin-top: 48px;
  margin-top: 16px;

  .left-list {
    flex: 1;
    background: #fff;
    border-radius: 8px;
    padding: 10px 16px 16px 16px;
    text-align: left;
    margin-right: 16px;

    .left-list-title {
      color: #181B22;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      height: 22px;
      line-height: 22px;
      margin-bottom: 12px;
      vertical-align: top;

      .question {
        width: 22px;
        height: 22px;
        margin-right: 8px;
        position: relative;
        top: 5px;
      }
    }

    .inner-item {
      height: 32px;
      line-height: 32px;
      background: #F4F6F9;
      border-radius: 6px;
      padding: 0 12px;
      margin-top: 10px;
    }

    .dot {
      display: inline-block;
      width: 4px;
      height: 4px;
      background: #387FFC;
      border-radius: 50%;
      margin-right: 9px;
      position: relative;
      top: -2px;
    }
  }

  .right-list {
    width: calc(50% - 8px);
    text-align: left;

    .inner-item {
      background-color: #fff;
      border-radius: 8px;
      margin-bottom: 16px;
      padding: 12px 16px;

      p {
        height: 22px;
        line-height: 22px;
      }

      .top-title {
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 6px;
      }

      .bottom-desc {
        color: #707786;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .inner-item {
    &:hover {
      background: rgba(51, 112, 255, 0.1);
      cursor: pointer;
    }

    &.disabled {
      &:hover {
        background: #f5f6f7;
      }
    }

    :deep(i) {
      color: #3370ff;
    }
  }
}

</style>
