<!--
 * @Author: ZHANGYU
 * @Date: 2024-06-11 17:26:51
 * @LastEditors: '张星' you@example.com
 * @Description:
 * @FilePath: /rafter-pro/views/page-view/src/views/schedule/Index.vue
 * @LastEditTime: 2024-07-04 11:03:17
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="ai-statistics-wrap">
    <div v-loading="historyLoading" class="left-history-cont">
      <el-input class="search-input" v-model="searchText" status="fill" placeholder="搜索历史记录" @keydown="onSearch">
        <template #innerPrepend>
          <i class="rafterfont rafter-icon-sousuo"></i>
        </template>
      </el-input>
      <div class="middle-list-cont">
        <template v-if="!isSearch">
          <div v-for="(item,index) in historyList" :key="index" class="history-main exceed-hidden">
            <div class="date-label">{{ item.date }}</div>
            <div v-for="(val,i) in item.list" :class="roomId === val.id?'active':''" :key="i" class="history-item">
              <p class="exceed-hidden" @click="getDialogueDetail(val.id)">{{ val.name }}</p>
              <Popconfirm cancelButtonText="取消" confirmButtonText="删除" title="删除" :content="`确定删除该条记录?`"
                          @confirm="onDeleteItem(val.id,index,i)">
                <template #reference>
                  <i class="rafterfont rafter-icon-shanchu del-icon"></i>
                </template>
              </Popconfirm>
            </div>
          </div>
        </template>
        <template v-else>
          <template v-if="searchData.length">
            <div v-for="(val,i) in searchData" :class="roomId === val.id?'active':''"
                 @click="getDialogueDetail(val.room.id)" :key="i" class="history-item">
              <p class="exceed-hidden">{{ val.room?.name || val.question }}</p>
            </div>
          </template>
          <Empty style="margin-left: -30px" size="small" class="empty" describedat="暂无内容" v-else></Empty>
        </template>
      </div>
      <div class="add-cont">
        <el-button type="primary" status="Brand" :disabled="addDisabled" @click="createDialogue()" class="add-room-btn">
          <i style="font-size: 14px;margin-right: 5px;" class="rafterfont rafter-icon-bim_jia"></i> 新建对话
        </el-button>
      </div>
    </div>
    <div class="right-main-cont">
      <div class="overlay-box"></div>
      <div class="ai-box" ref="scrollDiv" @scroll="handleScrollTop">
        <div ref="dialogScrollbar" class="ai-chat-conent" id="aiCont">
          <div class="item-content" v-for="(item, index) in list" :key="index">
            <!-- 问题 -->
            <div class="item-content-problem" v-if="item.label">
              <div class="content-empty"></div>
              <div class="content">
                <!-- 文本 -->
                <div class="text">
                  {{ item.label }}
                </div>
              </div>
              <div class="avatar">
                <img
                    :src="userInfo?.avatar || 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/5d74960ee2c8503021a1be75b80f83f2.png'"
                    alt=""/>
              </div>
            </div>

            <!-- 回答 -->
            <div class="item-content-anser">
              <div class="avatar">
                <img v-if="item.type !== 'start'" src="../../assets/images/ai/icon_robot.png" alt=""/>
              </div>
              <Card class="content" shadow="never" body-style="{ padding: 0,boder: 'none' }"
                    :class="item.type === 'start'?'first-msg-cont':''">
                <div v-if="!item.formValue && item.type !== 'router' &&  item.type !== 'start'">
                  思考中1 <span class="dotting"></span>
                </div>
                <!-- 文本内容 -->
                <div class="text" v-else-if="item.type === 'router'">
                  跳转页面已结束
                </div>
                <!-- 开场白 -->
                <div class="anser-start" v-else-if="item.type === 'start'">
                  <img class="robot" src="../../assets/images/ai/icon_robot_big.png" alt="">
                  <p class="anser-start-title">你好，我是小椽</p>
                  <p class="anser-start-desc">在这里我可以帮助你更好使用系统，可以随时向我提问哦~</p>
                  <div class="anser-bottom-handle">
                    <div class="left-list">
                      <div class="left-list-title">
                        <img class="question" src="../../assets/images/ai/question.png" alt="">
                        <span>AI搜索</span>
                      </div>
                      <div class="inner-item mb-8" :class="index === item.value.msg.length-1?'no-margin':''"
                           v-for="(msg, index) in item.value.msg" :key="index">
                        <div class="cursor exceed-hidden" @click="clickStartMsg(msg)">
                          <span class="dot"></span>
                          {{ msg }}
                        </div>
                      </div>
                    </div>
                    <!-- <div class="right-list">
                      <div class="inner-item" :class="j === rightListData.length-1?'no-margin':''" v-for="(val, j) in rightListData" :key="j">
                        <div class="ellipsis-2 cursor" @click="clickStartMsg(val.name)">
                          <p class="top-title"><RaIcon icon="Edit"></RaIcon> {{ val.name }}</p>
                          <p class="bottom-desc exceed-hidden">{{ val.desc }}</p>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
                <!-- 呼叫表单 -->
                <div class="callForm" v-else-if="item.type === 'list'">
                  <div v-if="item.value?.lodding">
                    思考中2 <span class="dotting"></span>
                  </div>
                  <TableList :form_value="item.formValue"></TableList>
                  <div class="is_ful" v-if="item.value.is_ful"></div>
                </div>

                <!-- 呼叫表单 -->
                <div class="callForm" v-else-if="item.type === 'group'">
                  <div v-if="item.value?.lodding">
                    思考中3 <span class="dotting"></span>
                  </div>
                  <Group :form_value="item.formValue" @call-parent-method="cilckProject"></Group>
                  <div class="is_ful" v-if="item.value.is_ful"></div>
                </div>

                <!-- 呼叫表单 -->
                <div class="text" v-else-if="item.type === 'other'">
                  {{ JSON.parse(item.formValue) }}
                </div>

              </Card>
              <div class="content-empty"></div>
            </div>
            <div v-if="item.type !== 'start' && item.order === list.length - 1  && !streamingDone">
              <el-button status="text" @click="streamingDone = true" class="stop-btn">
                停止回答
              </el-button>
            </div>
          </div>
          <div v-if="isUploadRecorder" class="item-content" style="margin-right: 40px">
            <!-- 问题 -->
            <div class="item-content-problem">
              <div class="content-empty"></div>
              <div class="content">
                <!-- 文本 -->
                <div class="text">
                  解析中...
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Empty class="empty" describedat="暂无内容" v-if="!loading && !list.length"></Empty>
      <div class="operate-textarea">
<!--        <i class="rafterfont rafter-icon-yuyin" @click="speakClick"></i>-->
        <el-input id="ai-input" ref="quickInputRef" v-model="searchTxt" placeholder="输入内容开始对话，Shift+回车换行"
                  type="textarea"
                  rows="3"
                  maxLength="1024" @keydown.enter.native="doSearch">
        </el-input>
        <div class="operate" @click="doSearch">
          <img v-if="!searchTxt" src="../../assets/images/ai/icon_send.png" alt=""/>
          <img v-else src="../../assets/images/ai/icon_send_colorful.png" alt=""/>
        </div>
      </div>
    </div>

    <div
        v-if="isRecorder"
        class="prompt-layer"
    >
      <div class="prompt-loader">
        <div class="em" v-for="(item, index) in 15" :key="index"></div>
      </div>
      <div class="click-container">
        <div class="button-click" @click="cancelRecorder">取消录音</div>
        <div class="button-click" @click="speakEndClick">完成录音</div>
      </div>
    </div>
  </div>
</template>

<script>
import TableList from './components/table/index.vue';
import Group from './components/group/index.vue';
import {getUserInfo} from '@/utils/tools';
import {HZRecorder} from "@/utils/HZRecorder.js";
import {mapState, mapMutations} from 'vuex';
import {dataInterface} from "../../apis/data";
import {Card, Empty, Scrollbar, Popconfirm} from 'element-ui';

export default {
  components: {
    TableList,
    Group,
    Card,
    Empty,
    Scrollbar,
    Popconfirm,
  },
  data() {
    return {
      userInfo: null,
      timer: null,
      searchText: '',
      roomId: '',
      list: [],
      loading: false,
      historyLoading: true,
      streamingDone: true,
      isSearch: false,
      addDisabled: false,
      historyList: [
        {date: '当天', list: []},
        {date: '最近7天', list: []},
        {date: '最近半年', list: []}
      ],
      searchData: [],
      sceneValue: '',
      searchTxt: '',
      searchTxtCopy: '',
      baselistItem: {
        label: '',
        type: 'start',
        value: {
          title: '您好，我是您的 AI 助手-小椽，我可以帮助您使用系统，您可以随时向我提问。',
          msg: [
            '查看系统最近七天内的待办情况',
            '查看系统最近一个月的待办情况',
            '查看系统最近一年的待办情况',
          ]
        },
        order: 0
      },
      isFirstMsg: true,
      dialogScrollbar: null,
      scrollTop: 0,
      scorll: true,
      recorder: null,
      isRecorder: false,
      isUploadRecorder: false
    };
  },
  computed: {
    ...mapState(['someState'])
  },
  watch: {
    historyList: {
      handler() {
        this.addDisabled = false;
        for (let i = 0; i < this.historyList.length; i++) {
          if (this.historyList[i]?.date === '当天') {
            const findData = this.historyList[i].list.filter(v => v.name === '未命名会话');
            if (findData?.length) {
              this.addDisabled = true;
            }
          }
        }
      },
      deep: true
    },
    searchText: {
      handler() {
        if (!this.searchText) {
          this.searchData = [];
          this.isSearch = false;
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(['someMutation']),
    doSearch(event) {
      if (event.keyCode == 13 && event.shiftKey) {
        return;
      }
      if (!this.searchTxt || this.loading) return;
      const content = this.searchTxt;
      if (this.list[this.list.length - 1].type != 'callForm') {
        this.isFirstMsg = false;
        this.order += 1;
        const msg = {
          label: content,
          type: '',
          value: '',
          order: this.order
        };
        this.list.push(msg);
        this.$nextTick(() => {
          console.log(this.$refs.scrollDiv,'this.$refs.scrollDiv', this.getMaxHeight())
            this.$refs.scrollDiv.scrollTop = this.getMaxHeight();
        });
      }
      this.searchTxtCopy = content;
      this.searchTxt = '';
      event.preventDefault();
      this.getScene(content);
    },
    cilckProject(content) {
      if (this.list[this.list.length - 1].type != 'callForm') {
        this.isFirstMsg = false;
        this.order += 1;
        const msg = {
          label: content,
          type: '',
          value: '',
          order: this.order
        };
        this.list.push(msg);
        this.$nextTick(() => {
            this.$refs.scrollDiv.scrollTop = this.getMaxHeight();
        });
      }
      this.searchTxtCopy = content;
      this.searchTxt = '';
      this.getScene(content);
    },
    clickStartMsg(value) {
      this.searchTxtCopy = value;
      if (this.list[this.list.length - 1].type != 'callForm') {
        this.isFirstMsg = false;
        this.order += 1;
        const msg = {
          label: value,
          type: '',
          value: '',
          order: this.order
        };
        this.list.push(msg);
        this.searchTxt = '';
        this.$nextTick(() => {
            this.$refs.scrollDiv.scrollTop = this.getMaxHeight();
        });
      }
      this.getScene(value);
    },
    editMsg(data) {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].order === data.order) {
          if (data.type === 'text') {
            this.streamingDone = false;
            let bol = true;
            for (var j = 0; j < data.value.length; j++) {
              setTimeout(() => {
                if (!this.streamingDone) {
                  this.list[i].value += data.value[j];
                  this.$refs.scrollDiv.scrollTo({top: this.getMaxHeight(), behavior: 'smooth'});
                } else {
                  if (bol) {
                    // this.pushDialogue(data);
                    bol = false;
                  }
                }
                if (this.list[i].value.length === data.value.length) {
                  this.streamingDone = true;
                  // this.pushDialogue(data);
                }
              }, j * 50);
            }
          } else {
            this.list[i].value = data.value;
            this.list[i].formValue = data.formValue;
          }
          this.list[i].type = data.type;
          if (+data.order === 1) {
            for (let i = 0; i < this.historyList[0].list.length; i++) {
              if (this.historyList[0].list[i].id === this.roomId) {
                this.historyList[0].list[i].name = this.searchTxtCopy;
              }
            }
          }
          break;
        }
      }
    },
    getScene(value) {
      const maxIndex = this.list.length - 1;
      if (this.list[maxIndex].type == 'callForm') {
        this.list[maxIndex].value.lodding = true;
      }
      dataInterface({say: value, group_id: this.roomId}, 'api/newChat/unpack').then(async (res) => {
        if (res.data.code === 200) {
          if (['group', 'list','other'].includes(res.data.data.type)) {
            this.sceneValue = res.data.data.type;
            this.tableList(value, res.data.data);
          } else {
            // this.order += 1;
            // const msg = {
            //   label: value,
            //   type: 'router',
            //   value: '',
            //   order: this.order
            // };
            // this.list.push(msg);
            // await this.pushDialogue(msg);
            // if (res.data.data.includes('changeRoute')) {
            //   const arr = res.data.data.split(':');
            //   this.$router.push({
            //     path: arr[1],
            //     query: {
            //       search: arr[2]
            //     }
            //   });
            // }
            return;
          }
        } else {
          if (this.list[maxIndex].type == 'callForm') {
            this.list[maxIndex].value.lodding = false;
          }
          this.editMsg({
            label: value,
            type: 'text',
            value: "未识别到有用信息2",
            order: this.order
          });
        }
      }).catch(() => {
        if (this.list[maxIndex].type == 'callForm') {
          this.list[maxIndex].value.lodding = false;
        }
        this.editMsg({
          label: value,
          type: 'text',
          value: "未识别到有用信息1",
          order: this.order
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    onDeleteItem(id, index, i) {
      dataInterface({room_id: id}, 'api/newChat/delChat').then((res) => {
        if (res.data.code === 200) {
          this.$message('删除成功！');
          this.historyList[index].list.splice(i, 1);
          if (id === this.roomId) {
            if (this.historyList[index].list.length) {
              this.roomId = this.historyList[index].list[0].id;
              this.getDialogueDetail(this.roomId, false);
            } else {
              if (index === 0) {
                this.createDialogue();
              } else {
                if (this.historyList[0].list.length) {
                  this.roomId = this.historyList[0].list[0].id;
                  this.getDialogueDetail(this.roomId, false);
                } else {
                  this.createDialogue();
                }
              }
            }
          }
        } else {
          this.$message(res.data.msg);
        }
      });
    },
    getHistoryData() {
      this.historyList = [
        {date: '当天', list: []},
        {date: '最近7天', list: []},
        {date: '最近半年', list: []}
      ];
      this.isSearch = false;
      dataInterface({type: "current"}, 'api/newChat/chatList', 'GET').then((res) => {
        if (res.data.code === 200) {
          this.historyList[0].list = res.data.data.today || [];
          this.historyList[1].list = res.data.data.sevenDay || [];
          this.historyList[2].list = res.data.data.sixMouthDay || [];
          if (!res.data.data.today?.length) {
            this.createDialogue();
          } else {
            this.roomId = res.data.data.today[0].id;
            this.getDialogueDetail(this.roomId, false);
          }
        }
        this.historyLoading = false;
      }).catch(() => {
        this.historyLoading = false;
      }).finally(() => {
        this.historyLoading = false;
      });
    },
    other(value) {
      this.loading = true;
      if (this.isFirstMsg) {
        this.order += 1;
        const msg = {
          label: value,
          type: '',
          value: '',
          order: this.order
        };
        this.list.push(msg);
      }
      this.isFirstMsg = true;
      this.$nextTick(() => {
          this.$refs.scrollDiv.scrollTop = this.getMaxHeight();
      });
      dataInterface({say: value}, 'api/graph/1055').then((res) => {
        if (res.data.code === 200) {
          this.editMsg({
            label: value,
            type: 'text',
            value: res.data.data,
            order: this.order
          });
        } else {
          this.editMsg({
            label: value,
            type: 'text',
            value: res.data.msg,
            order: this.order
          });
        }
        this.loading = false;
      }).catch(() => {
        this.editMsg({
          label: value,
          type: 'text',
          value: "未识别到有用信息",
          order: this.order
        });
      }).finally(() => {
        this.loading = false;
        this.$nextTick(() => {
            this.$refs.scrollDiv.scrollTop = this.getMaxHeight();
        });
      });
    },
    tableList(value, data) {
      this.loading = true;
      this.editMsg({
        label: value,
        formValue: data.list.form_value,
        type: data.type,
        value: {
          content: data.list.answer,
          lodding: false
        },
        order: this.order,
      });
      this.isFirstMsg = true;
      this.loading = false;
      this.$nextTick(() => {
          this.$refs.scrollDiv.scrollTop = this.getMaxHeight();
      });
    },
    getMaxHeight() {
      return this.$refs.dialogScrollbar?.scrollHeight;
    },
    handleScrollTop($event) {
      this.scrollTop = $event.scrollTop;
      if (
          this.$refs.dialogScrollbar.scrollHeight - (this.scrollTop + this.$refs.scrollDiv.scrollTop) <=
          30
      ) {
        this.scorll = true;
      } else {
        this.scorll = false;
      }
      this.$emit('scroll', {...$event, dialogScrollbar: this.$refs.dialogScrollbar, scrollDiv: this.$refs.scrollDiv});
    },
    onSearch() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (!this.searchText) return;
        this.historyLoading = true;
        dataInterface({name: this.searchText}, 'api/chat/search', 'GET').then((res) => {
          this.isSearch = true;
          if (res.data.code === 200) {
            if (res.data.data?.length) {
              this.searchData = res.data.data;
            }
          }
          this.historyLoading = false;
        });
      }, 500);
    },
    createDialogue() {
      dataInterface({type: "current"}, 'api/newChat/createChat').then((res) => {
        if (res.data.code === 200 && res.data.data.id) {
          this.order = 0;
          this.historyList[0].list.unshift(res.data.data);
          this.roomId = res.data.data.id;
          this.list = [this.baselistItem];
        }
      });
    },
    getDialogueDetail(id, bol = true) {
      if (id === this.roomId && bol) return;
      this.roomId = id;
      dataInterface({group_id: this.roomId, type: "current"}, 'api/newChat/msgList', 'GET').then((res) => {
        this.list = [];
        if (res.data.code === 200) {
          if (res.data.data?.length) {
            this.order = res.data.data.length;
            this.list = res.data.data.map((item, index) => {
              const obj = {
                "label": item.question,
                "type": item.type,
                "order": index + 1,
                "id": item.id,
                "formValue": item.form_value
              };
              if (item.type === 'text') {
                obj.value = item.answer;
              } else if (['list', 'group'].includes(item.type)) {
                obj.value = JSON.parse(item.answer);
              }
              return obj;
            });
            this.list.unshift({
              ...this.baselistItem,
              order: 0
            });
            this.$nextTick(() => {
                this.$refs.scrollDiv.scrollTop = this.getMaxHeight();
            });
          } else {
            this.list.push({
              ...this.baselistItem,
              order: 0
            });
          }
        }
      });
    },
    pushDialogue(data) {
      let content = data.value;
      if (typeof data.value === 'object') {
        content = JSON.stringify(data.value);
      }
      dataInterface({
        room_id: this.roomId,
        question: this.searchTxtCopy,
        content: content,
        type: data.type
      }, 'api/chat/createMsg').then((res) => {
        if (res.data.code === 200) {
          this.list[data.order].id = res.data.data.msg_id;
        }
      });
    },
    initRecorder(constrains) {
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(constrains).then(stream => {
          this.success(stream);
          this.recorder = new HZRecorder(stream);
          console.log('录音初始化准备完成');
        }).catch(err => {
          this.error(err);
        });
      } else if (navigator.webkitGetUserMedia) {
        navigator.webkitGetUserMedia(constrains).then(stream => {
          this.success(stream);
          this.recorder = new HZRecorder(stream);
          console.log('录音初始化准备完成');
        }).catch(err => {
          this.error(err);
        });
      } else if (navigator.mozGetUserMedia) {
        navigator.mozGetUserMedia(constrains).then(stream => {
          this.success(stream);
          this.recorder = new HZRecorder(stream);
          console.log('录音初始化准备完成');
        }).catch(err => {
          this.error(err);
        });
      } else if (navigator.getUserMedia) {
        navigator.getUserMedia(constrains).then(stream => {
          this.success(stream);
          this.recorder = new HZRecorder(stream);
          console.log('录音初始化准备完成');
        }).catch(err => {
          this.error(err);
        });
      }
    },
    success(stream) {
      console.log(this.recorder, 'stream this.recorder@@@@');
      console.log('已点击允许,开启成功', stream);
    },
    error(error) {
      console.log('访问用户媒体设备失败：', error.name, error.message);
    },
    speakClick() {
      if (this.isRecorder || !this.recorder) {
        if (!this.isRecorder && !this.recorder) {
          this.error('未授权或者设备不支持!');
        }
        if (this.isRecorder && this.recorder) {
          this.warning('正在进行!');
        }
        return;
      }
      this.recorder && this.recorder.start();
      this.isRecorder = true;
    },
    cancelRecorder() {
      if (!this.recorder) return;
      this.recorder.stop();
      this.recorder.destory();
      this.recorder = null;
      this.initRecorder({video: false, audio: true});
      this.isRecorder = false;
    },
    async speakEndClick() {
      this.isRecorder = false;
      if (!this.recorder) return;
      this.recorder.stop();
      let audioData = new FormData();
      const newbolb = this.recorder.getBlob();
      const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav', {
        type: "audio/wav",
      });
      audioData.append('file', fileOfBlob);
      this.recorder.destory();
      this.recorder = null;
      this.initRecorder({video: false, audio: true});
      this.isUploadRecorder = true;
      this.$nextTick(() => {
          this.$refs.scrollDiv.scrollTop = this.getMaxHeight();
      });
      const res = await fetch('https://dev.bimcc.net/api/tech/speech', {
        body: audioData,
        method: 'POST',
      }).then((resE) => {
        return resE.json();
      });
      this.isUploadRecorder = false;
      if (res) {
        this.clickStartMsg(res.data);
      }
    }
  },
  mounted() {
    this.userInfo = getUserInfo();
    this.list.push(this.baselistItem);
    this.getHistoryData();
    if (navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia) {
      this.initRecorder({video: false, audio: true});
    } else {
      console.log('你的浏览器不支持访问用户媒体设备');
    }
  }
};
</script>

<style lang="less" scoped>
.prompt-layer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.prompt-loader {
  width: auto;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}

.click-container {
  width: auto;
  height: 32px;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-top: 16px;

  .button-click {
    width: 100px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 6px;
    user-select: none;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
      color: var(--icon-on-surface-brand);
      border-color: var(--icon-on-surface-brand);
    }
  }
}

.prompt-loader .em {
  display: block;
  background: #fff;
  width: 6px;
  height: 60%;
  margin-right: 15px;
  float: left;
}

.prompt-loader .em:last-child {
  margin-right: 0px;
}

.prompt-loader .em:nth-child(1) {
  animation: load 2.5s 1.4s infinite linear;
}

.prompt-loader .em:nth-child(2) {
  animation: load 2.5s 1.2s infinite linear;
}

.prompt-loader .em:nth-child(3) {
  animation: load 2.5s 1s infinite linear;
}

.prompt-loader .em:nth-child(4) {
  animation: load 2.5s 0.8s infinite linear;
}

.prompt-loader .em:nth-child(5) {
  animation: load 2.5s 0.6s infinite linear;
}

.prompt-loader .em:nth-child(6) {
  animation: load 2.5s 0.4s infinite linear;
}

.prompt-loader .em:nth-child(7) {
  animation: load 2.5s 0.2s infinite linear;
}

.prompt-loader .em:nth-child(8) {
  animation: load 2.5s 0s infinite linear;
}

.prompt-loader .em:nth-child(9) {
  animation: load 2.5s 0.2s infinite linear;
}

.prompt-loader .em:nth-child(10) {
  animation: load 2.5s 0.4s infinite linear;
}

.prompt-loader .em:nth-child(11) {
  animation: load 2.5s 0.6s infinite linear;
}

.prompt-loader .em:nth-child(12) {
  animation: load 2.5s 0.8s infinite linear;
}

.prompt-loader .em:nth-child(13) {
  animation: load 2.5s 1s infinite linear;
}

.prompt-loader .em:nth-child(14) {
  animation: load 2.5s 1.2s infinite linear;
}

.prompt-loader .em:nth-child(15) {
  animation: load 2.5s 1.4s infinite linear;
}

@keyframes load {
  0% {
    height: 10%;
  }

  50% {
    height: 100%;
  }

  100% {
    height: 10%;
  }
}

.ai-statistics-wrap {
  width: 100%;
  height: 100%;
  background: var(--overall-surface-variant, #F2F5FA);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;

  :deep(.el-textarea__inner) {
    border: unset;
  }
  :deep(.el-card) {
    border: unset;
  }

  .left-history-cont {
    width: 200px;
    height: calc(100% - 24px);
    padding: 12px 8px;
    background: #fff;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    .search-input {
      margin-bottom: 12px;
    }

    .middle-list-cont {
      flex: 1;
      overflow-y: auto;
      position: relative;

      .history-main {
        .date-label {
          height: 28px;
          line-height: 28px;
          color: #787F8F;
          font-family: "PingFang SC";
          font-size: 12px;
          padding-left: 2px;
          font-weight: 500;
        }
      }

      .history-item {
        display: flex;
        flex-direction: row;
        width: calc(100% - 26px);
        height: 36px;
        line-height: 38px;
        padding: 0px 12px;
        gap: 6px;
        border-radius: 6px;
        border: 1px solid rgba(240, 241, 244, 0.60);
        margin-bottom: 8px;
        cursor: pointer;

        p {
          color: #181B22;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 38px;
          text-align: left;
          flex: 1;
        }

        .del-icon {
          display: none;
        }
      }

      .active {
        background: #D1E3FF;
      }

      .history-item:hover {
        background: #F4F6F9;

        .del-icon {
          display: block;
        }
      }
    }

    .add-cont {
      text-align: center;

      .add-room-btn {
        margin: 54px 0;
        border: none;
        //color: #387FFC;
      }
    }
  }

  .right-main-cont {
    padding: 16px;
    flex: 1;
    position: relative;
    cursor: default;

    .overlay-box {
      width: 100%;
      height: 68px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      background: linear-gradient(180deg, #F4F6F9 0%, #F4F6F9 50%, rgba(244, 246, 249, 0.00) 100%);
    }
  }

  :deep(.ra-scrollbar) {
    background: transparent;

    .ra-scrollbar__view {
      min-height: 100%;
      height: 100%;
      max-height: 99999999px;
    }
  }

  .input-wrap {
    width: 30%;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);

    :deep(.input-main-box) {
      .input-box {
        border-radius: 80px;
        height: 40px;
      }
    }
  }

  .search-list {
    width: 300px;
    height: 400px;
    background: rgba(0, 0, 0, 0.1);
    padding: 46px 16px 16px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 6px;
    overflow-y: auto;
    position: fixed;
    right: 30px;
    bottom: 30px;

    .close {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      cursor: pointer;

      i {
        font-size: 20px;
      }

      &:hover {
        background: #fff;
      }
    }

    .item {
      width: 100%;
      height: auto;
      display: flex;
      align-items: baseline;
      cursor: pointer;

      .block {
        display: inline-block;
        height: auto;
        padding: 2px 12px;
        background: green;
        border-radius: 16px;
        line-height: 20px;
        margin-right: 4px;
        color: #fff;
        font-size: 12px;
      }

      .label {
        flex: 1;
        line-height: 18px;
        overflow: hidden;
      }
    }
  }

  .empty {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -170px 0 0 -60px;
  }

  /* loading动画 */

  .loading-container {
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);

    .g-container {
      margin: auto;
      position: relative;
      width: 30px;
      height: 30px;
      top: 50%;
      transform: translateY(-50%);
    }

    @count: 7;

    .g-ball,
    .g-first {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--icon-on-surface-brand);
      border-radius: 50%;
      transform: translate(-700%, 0);
      opacity: 0;
      // animation: move 3s infinite linear;
    }

    .loop(@i) when (@i <= @count) {
      .g-ball:nth-child(@{i}) {
        animation: move 3.5s infinite (@i * 0.2 + 0.1s) linear;
      }
      .loop(@i + 1);
    }
    .loop(0);

    .g-first {
      animation: scaleMove 3.5s infinite linear;
    }

    @keyframes move {
      25% {
        opacity: 1;
        transform: translate(-1vw, 0);
      }

      50% {
        opacity: 1;
        transform: translate(1vw, 0);
      }

      75%,
      100% {
        opacity: 0;
        transform: translate(700%, 0);
      }
    }

    @keyframes scaleMove {
      25% {
        opacity: 1;
        transform: translate(-1vw, 0);
      }

      35% {
        opacity: 1;
        transform: scale(1);
      }

      70% {
        opacity: 1;
        transform: translate(1vw, 0) scale(2);
      }

      90%,
      100% {
        opacity: 0;
        transform: translate(1vw, 0) scale(1);
      }
    }
  }

  .loading-label {
    position: absolute;
    top: 60%;
    width: 100%;
    height: 24px;
    color: var(--icon-on-surface-brand);
    text-align: center;
    z-index: 10;
    font-size: 20px;
  }

  .loading-label::after {
    content: "";
    animation: dot 3s infinite steps(3, start);
    line-height: 20px;
  }

  @keyframes dot {
    33.33% {
      content: ".";
    }

    66.67% {
      content: "..";
    }

    100% {
      content: "...";
    }
  }

  .stop-btn {
    margin-left: 38px;
  }

  /* input框样式 */

  :deep(.operate-textarea) {
    .input-main-box.border-class .ra-textarea {
      box-shadow: none !important;
      border: none;
    }

    .input-main-box.border-class .ra-textarea:hover:not(.input-validate_error):not(.is-disabled):not(.small-class):not(.is-select-focus):not(.is-focus) {
      box-shadow: none !important;
      border: none;
    }

    .input-main-box.border-class .ra-textarea:focus-within:not(.input-validate_error):not(.is-disabled):not(.small-class):not(.is-select-focus):not(.is-focus) {
      box-shadow: none !important;
      border: none;
    }

    /* 设置滚动条样式 */

    ::-webkit-scrollbar {
      width: 0; /* 设置滚动条的宽度 */
    }

    /* 定义滑块（thumb） */

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  .operate-textarea {
    box-shadow: 0px 6px 24px 0px rgba(31, 35, 41, 0.08);
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: calc(50% - 118px);
    position: absolute;
    bottom: 44px;
    left: 50%;
    transform: translateX(-50%);

    .rafter-icon-yuyin {
      font-size: 28px;
      position: absolute;
      left: -40px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:has(.ra-input:focus) {
      border: 1px solid #3370FF;
    }

    :deep(.input-main-box) {
      .input-box {
        border-radius: 80px;
        height: 40px;
      }
    }

    &:has(.el-textarea__inner:focus) {
      border: 1px solid var(--el-color-primary);
    }

    :deep(.el-textarea__inner) {
      border-radius: 8px !important;
      box-shadow: none;
      resize: none;
      padding: 12px 16px;
      box-sizing: border-box;
    }

    .operate {
      padding: 6px 10px;
      cursor: pointer;

      .sent-button {
        max-height: none;

        .el-icon {
          font-size: 24px;
        }
      }

      img {
        width: 48px;
        margin-top: 30px;
      }

      :deep(.el-loading-spinner) {
        margin-top: -15px;

        .circular {
          width: 31px;
          height: 31px;
        }
      }
    }
  }

  /* 聊天内容 */

  .ai-box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 10px;
    height: calc(100% - 170px);
    box-sizing: border-box;
    z-index: 5;
    overflow-y: auto;


    :deep(.el-scrollbar__wrap) {
      width: 100%;
      padding: 10px;
      scrollbar-width: none;
    }

    .ai-chat-conent {
      padding-bottom: 20px;

      .item-content {
        padding-top: 25px;
        box-sizing: border-box;

        .item-content-problem {
          display: flex;
          margin-bottom: 20px;
          justify-content: flex-end;

          .content-empty {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }

          :deep(.el-card) {
            border: none;
            box-shadow: none;
          }

          .content {
            flex: 1;
            min-width: 0px;
            white-space: normal;
            height: auto;
            overflow-wrap: break-word;
            word-break: break-all;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .text {
              padding: 12px;
              text-align: left;
              color: #181B22;
              border-radius: 8px;
              background-color: #D1E3FF;
              font-family: "PingFang SC";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }
          }

          .avatar {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            background-color: #f6f6f6;
            overflow: hidden;

            img {
              width: 100%;
            }
          }
        }
      }

      .item-content-anser {
        display: flex;
        width: 100%;

        .first-msg-cont {
          background: transparent !important;
        }

        .first-msg-cont:hover {
          box-shadow: none !important;
        }

        .avatar {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;

          img {
            width: 100%;
          }
        }

        .content-empty {
          width: 30px;
          height: 30px;
          margin-left: 10px;
        }

        .content {
          flex: 1;
          min-width: 0;
          overflow: hidden;

          border-radius: 12px;

          :deep(.fliper-wrap) {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
          }

          .text {
            padding-bottom: 8px;
            padding-top: 8px;
            box-sizing: border-box;
          }

          .callForm {
            min-height: 300px;
            position: relative;

            .is_ful {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              padding: 100px;
              background-color: #f5f6f7;
              color: #fff;
              text-align: center;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

/* 开场白 */
.anser-start {
  width: 90%;
  margin: auto;
  text-align: center;

  .robot {
    width: 70px;
    height: 70px;
  }

  .anser-start-title {
    color: #181B22;
    margin: 0;
    font-family: "PingFang SC";
    font-size: 28px;
    height: 42px;
    line-height: 42px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .anser-start-desc {
    font-family: "PingFang SC";
    color: #707786;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .anser-bottom-handle {
    display: flex;
    flex-direction: row;
    // margin-top: 48px;
    margin-top: 16px;

    .left-list {
      flex: 1;
      background: #fff;
      border-radius: 8px;
      padding: 10px 16px 16px 16px;
      text-align: left;
      margin-right: 16px;

      .left-list-title {
        color: #181B22;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        height: 22px;
        line-height: 22px;
        margin-bottom: 12px;
        vertical-align: top;

        .question {
          width: 22px;
          height: 22px;
          margin-right: 8px;
          position: relative;
          top: 5px;
        }
      }

      .inner-item {
        height: 32px;
        line-height: 32px;
        background: #F4F6F9;
        border-radius: 6px;
        padding: 0 12px;
      }

      .dot {
        display: inline-block;
        width: 4px;
        height: 4px;
        background: #387FFC;
        border-radius: 50%;
        margin-right: 9px;
        position: relative;
        top: -2px;
      }
    }

    .right-list {
      width: calc(50% - 8px);
      text-align: left;

      .inner-item {
        background-color: #fff;
        border-radius: 8px;
        margin-bottom: 16px;
        padding: 12px 16px;

        p {
          height: 22px;
          line-height: 22px;
        }

        .top-title {
          font-family: "PingFang SC";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          margin-bottom: 6px;
        }

        .bottom-desc {
          color: #707786;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }

    .inner-item {
      &:hover {
        background: rgba(51, 112, 255, 0.1);
        cursor: pointer;
      }

      &.disabled {
        &:hover {
          background: #f5f6f7;
        }
      }

      :deep(i) {
        color: #3370ff;
      }
    }
  }

  /*
  双行超出省略号，其他行数自定义 -webkit-line-clamp
*/

  .ellipsis-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .no-margin {
    margin: 0 !important;
  }
}

.exceed-hidden {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* 正在回答动画 */
.dotting {
  display: inline-block;
  width: 10px;
  min-height: 2px;
  padding-right: 2px;
  margin-left: 2px;
  padding-left: 2px;
  border-left: 2px solid currentColor;
  border-right: 2px solid currentColor;
  background-color: currentColor;
  background-clip: content-box;
  box-sizing: border-box;
  -webkit-animation: dot 0.8s infinite step-start both;
  animation: dot 0.8s infinite step-start both;

  &:before {
    content: '...';
  }

  &::before {
    content: '';
  }
}

@-webkit-keyframes dot {
  25% {
    border-color: transparent;
    background-color: transparent;
  }

  50% {
    border-right-color: transparent;
    background-color: transparent;
  }

  75% {
    border-right-color: transparent;
  }
}

@keyframes dot {
  25% {
    border-color: transparent;
    background-color: transparent;
  }

  50% {
    border-right-color: transparent;
    background-color: transparent;
  }

  75% {
    border-right-color: transparent;
  }
}

@media (max-width: 1200px) {
  .ai-box {
    width: 80% !important;
  }

  .operate-textarea {
    width: calc(80% - 118px) !important;
  }
}

@media (min-width: 1200px) {
  .ai-box {
    width: 50% !important;
  }

  .operate-textarea {
    width: calc(50% - 118px) !important;
  }
}
</style>
