<template>
  <div>
    <el-table
        :data="tableData"
        style="width: 100%"
        headerFill="none"
        @current-change="handleCurrentChange"
    >
      <el-table-column prop="data_archi_name" label="项目" width="100"/>
      <el-table-column prop="object_name" label="所属功能" width="70"/>
      <el-table-column prop="content" label="通知内容" min-width="100"/>
      <el-table-column prop="expiration_date" label="截止时间" width="90"/>
      <el-table-column prop="from_user_name" label="发起人" width="70"/>
      <el-table-column  label="逾期天数" width="70">
        <template #default="scope">
        {{ overdueDays(scope.row.expiration_date) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="80">
        <template #default="scope">
          <el-button
              size="mini"
              type="primary"
              status="text"
              icon="Edit"
              @click="handleEdit(scope.$index, scope.row)"
              v-if="scope.row.form_uuid !== ''"
          >去处理
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding-top: 20px;width: 100%">
      <a href="/base-home" style="margin-right: 0px">>全部待办</a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    form_value: {
      type: String,
      default: () => {
        return '[]';
      }
    }
  },
  data() {
    return {
      tableData: []
    };
  },
  created() {
    this.tableData = JSON.parse(this.form_value);
  },
  methods: {
    handleCurrentChange(val) {
      console.log(val, '单选选中数据');
    },
    handleEdit(index, row) {
      const data = {
        event: 'showDetailModal',
        form_uuid: row.form_uuid,
        use_object_uuid:row.object_uuid,
        is_detail:true,
        data_id: row.data_id,
        flow_id: row.flow_id,
        object_name: row.object_name,
        module_name: row.module_name,
        archi_type: row.data_archi_type,
      }
      window.parent.postMessage(data, '*');
    },
    overdueDays(time) {
      const start = new Date(time);
      const now = new Date();
      const diffTime = Math.abs(now - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    }

  }
};
</script>
